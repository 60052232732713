import { observable, action } from 'mobx'
// import axios from 'axios'

export default class UserStore {
    @observable permission = 0;

    @observable uid = null;

    @action getUserInfo = () => {
      // let accessToken = localStorage.getItem('accessToken')
      // if (accessToken) {
      //   axios.get(`${process.env.REACT_APP_USER_API}/users`, {
      //     headers: { access_token: accessToken },
      //     params: { permission: 1 }
      //   }).then(res => {
      //     if (res.data.permission > 0){
      //       this.uid = res.data.uid
      //       this.permission = res.data.permission
      //     } else {
      //       alert('권한이 없는 사용자 입니다')
      //       this.logout()
      //     }
      //   }).catch(err => {
      //     if (!err.response) {
      //       alert('서버에 연결하지 못 했습니다')
      //     }
      //     else if (err.response.status === 401) {
      //       const refreshToken = localStorage.getItem('refreshToken')
      //       localStorage.removeItem('accessToken')
      //       axios.get(`${process.env.REACT_APP_USER_API}/login/token`, {
      //         headers: { refresh_token: refreshToken },
      //         params: { permission: 1 }
      //       }).then(res => {
      //         accessToken = res.data.access_token
      //         localStorage.setItem('accessToken', accessToken)
      //         localStorage.setItem('refreshToken', res.data.refresh_token)
      //         axios.get(`${process.env.REACT_APP_USER_API}/users`, {
      //           headers: { access_token: accessToken }
      //         }).then(res => {
      //           if (res.data.permission > 0){
      //             this.uid = res.data.uid
      //             this.permission = res.data.permission
      //           } else {
      //             alert('권한이 없는 사용자 입니다')
      //             this.logout()
      //           }
      //         })
      //       })
      //     }
      //   })
      // }
    }

    @action logout = () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      this.nick = ''
      this.uid = null
      window.location.reload()
    }
}
